import onLoadAsync from '../on_load_async.js';

onLoadAsync(() => {
  document.querySelectorAll('.dropdown-trigger').forEach((trigger) => {
    const link = trigger.querySelector('a');

    const dropdown = trigger.querySelector('.dropdown');

    link.addEventListener('click', (e) => {
      e.preventDefault();
      if (!dropdown.classList.contains('dropdown-no-wrap')) {
        dropdown.style.width = `${trigger.offsetWidth - 32}px`;
      }
      dropdown.classList.toggle('active');
    });

    document.addEventListener('click', (event) => {
      if (!trigger.contains(event.target)) {
        dropdown.classList.remove('active');
      }
    });
  });
});
