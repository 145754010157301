import onLoadAsync from '../shared/on_load_async.js';

function displayLoader() {
  document.querySelector('.spinner').classList.add('active');
}

onLoadAsync(() => {
  const links = document.querySelectorAll('.js-loader-link');
  links.forEach((link) => {
    link.addEventListener('click', () => {
      displayLoader();
    });
  });
});

export default displayLoader;
